import { useQuery } from "@tanstack/react-query"
import { IThemePosts } from "../dtos/IThemePosts.dto"
import { api } from "../services/api"

export const useThemePosts = () => {
  return useQuery(["themePosts"], async () => {
    const { data } = await api.get<IThemePosts>(`/wp/v2/tema_das_postagens`)
    return data
  })
}
